import honeyReducer from './honeyReducer';
import detailReducer from './detailReducer';
import {combineReducers} from 'redux';
import {firestoreReducer} from 'redux-firestore';
import { firebaseReducer } from "react-redux-firebase";
import authReducer from './authReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    honeys: honeyReducer,
    detail: detailReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer
})

export default rootReducer;