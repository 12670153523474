import React, { Component } from 'react'
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import {connect} from 'react-redux';
import displayInfo from '../actions/displayinfo';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';

// import M from 'materialize-css';
// import {
//   FacebookShareButton,
//   FacebookIcon,
//   TwitterShareButton,
//   TwitterIcon,
//   PinterestShareButton,
//   PinterestIcon
// } from 'react-share';

import "../Social.css";
// import media from "../media.jpg"
import truck from "../truckIcon.png";
// import fb from "../fb.png";
// import twt from "../twt.png";
// import instagram from "../instagram.png";
mapboxgl.accessToken = 'pk.eyJ1Ijoic3R1YmVycnkiLCJhIjoiY2pweHMxcXg2MWM3MjN5bzA0dXo4a3c2bSJ9.QUVCnGo1lETW5x-96q1smw';

export class Map extends Component {

  // Inline Styles
  style={
    display: 'block',
    width: '100%',
    height: '100%',
    minHeight: '100%'
  }
  cardStyle= {
    height: '80vh'
  }
  notif = null;

  animating = true;
  modalInstance;
  // Converts string coords in to array coords
  convertCoord = (coord)=>{
    // console.log(coord)
    try {
      let data = JSON.parse(coord)
      return data
    } catch (error) {
      coord = coord.split(',');
      let data = coord.map((num) => {
          return +num
      })
      return data;
    }
  }

  createMap(){
    // Instantiate Map
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      zoom: 3,
      center: [22.492280778550025,17.82690062978301],
      style: 'mapbox://styles/mapbox/satellite-v9'
      // style: 'mapbox://styles/mapbox/satellite-v9'
      // style: 'mapbox://styles/mapbox/streets-v10'
    })
    
    // Instantiate Bound
    let bounds = new mapboxgl.LngLatBounds();
    // console.log(this.props)
    // console.log(this.props.id);
    let pointLayer;

    // Animation Function 6 Seconds Interval
    const animateMap = (geojson)=>{
      const handlers = ['scrollZoom','boxZoom','dragRotate','dragPan','keyboard','doubleClickZoom','touchZoomRotate']
      handlers.forEach(handler=>{
          this.map[handler].disable();
      })
      let interContinental = false;
      let features = geojson.features;
      let count = features.length;
      const animate = ()=>{
        count--
        // console.log('count', count)
        // console.log(features[count].properties.locationType)
        if(features[count].properties.locationType !== "intermediate"){
          this.map.flyTo({
            center: features[count].geometry.coordinates,
            zoom: 10,
            pitch: 30,
            speed: 0.75
          })
          this.map.once('zoomend', ()=>{
            // console.log(features[count].properties.locationType)
            if(features[count].properties.locationType === 'beekeeper'){
              // console.log('CAR 15');
              this.map.setLayoutProperty('point', 'visibility', 'none')
              // this.map.setLayoutProperty('point', 'icon-image', 'truck')
              // console.log(this.map.getLayoutProperty('point', 'visibility'))
            }
            if(features[count].properties.locationType === 'processing'){
              // console.log('CAR 15');
              // this.map.setLayoutProperty('point', 'visibility', 'visible')
              this.map.setLayoutProperty('point', 'icon-image', 'truck')
              // console.log(this.map.getLayoutProperty('point', 'visibility'))
            }
            if(features[count].properties.locationType === 'export'){
              // console.log('CAR 15');
              this.map.setLayoutProperty('point', 'icon-image', 'truck')
              // console.log(this.map.getLayoutProperty('point', 'visibility'))
              interContinental = false
            }
            if(features[count].properties.locationType === 'import'){
              // console.log('ferry-15');
              this.map.setLayoutProperty('point', 'icon-image', 'ferry-15')
              // console.log(this.map.getLayoutProperty('point', 'visibility'))
              interContinental = true
            }
            if(features[count].properties.locationType === 'distribution'){
              // console.log('CAR 15');
              this.map.setLayoutProperty('point', 'icon-image', 'truck')
              // console.log(this.map.getLayoutProperty('point', 'visibility'))
            }
            if(features[count].properties.locationType === 'distribution2'){
              this.map.setLayoutProperty('point', 'icon-image', 'truck')
              this.map.setLayoutProperty('point', 'visibility', 'visible')
              // console.log(this.map.getLayoutProperty('point', 'visibility'))
            }
            let detail = features[count].properties;
            // console.log(detail)
            // this.props.displayInfo(detail);
            if(count-1>0){
              setTimeout(() => {
                // requestAnimationFrame(animate)
                animate()
              }, 6000);
            }else{
              // console.log('Toggle MODAL')
              this.map.setLayoutProperty('point', 'visibility', 'none')
              this.map.setPitch(0)
              this.map.fitBounds(bounds)
              this.map.once('zoomend', ()=>{
                  this.map.flyTo({
                      zoom: this.map.getZoom()-0.2
                    })
                  handlers.forEach(handler=>{
                      this.map[handler].enable();
                  })
                  // this.modalInstance.open();
                  this.animating = false
                  // map.setZoom(map.getZoom()-1)
                  // map.flyTo({center: geojson.features[0].geometry.coordinates, speed: 0.5})
                  // map.once('zoomend', ()=>{
                  //     map.flyTo({zoom: 1.2425770835732375, speed: 0.8})
                      // map.once('zoomend', ()=>{
                          // animate();
                          // handlers.forEach(handler=>{
                          //     map[handler].enable();
                          // })
                          // markers[0].togglePopup();
                      // })
                  // })
              })
            }
          })
        }else{
          if(interContinental){
            this.map.flyTo({
              center: features[count].geometry.coordinates,
              zoom: 5,
              pitch: 30,
              speed: 0.75
            })
          }else{
            this.map.flyTo({
              center: features[count].geometry.coordinates,
              zoom: 10,
              pitch: 30,
              speed: 0.75
            })
          }
          this.map.once('zoomend', ()=>{
            // requestAnimationFrame(animate)
            animate()
          })
        }
        // if(count+1<features.length){
        //   if(features[count].properties.locationType !== "intermediate"){
        //     setTimeout(() => {
        //       // requestAnimationFrame(animate)
        //       animate()
        //     }, 6000);
        //   }else{
        //     this.map.once('zoomend', ()=>{
        //       // requestAnimationFrame(animate)
        //       animate()
        //     })
        //   }
        // }else{
        //   // console.log('Toggle MODAL')
        //   this.map.once("zoomend", ()=>{
        //     this.map.setLayoutProperty('point', 'visibility', 'none')
        //     this.map.setPitch(0)
        //     this.map.fitBounds(bounds)
        //     this.map.once('zoomend', ()=>{
        //         this.map.flyTo({
        //             zoom: this.map.getZoom()-0.2
        //           })
        //         handlers.forEach(handler=>{
        //             this.map[handler].enable();
        //         })
        //         // this.modalInstance.open();
        //         this.animating = false
        //         // map.setZoom(map.getZoom()-1)
        //         // map.flyTo({center: geojson.features[0].geometry.coordinates, speed: 0.5})
        //         // map.once('zoomend', ()=>{
        //         //     map.flyTo({zoom: 1.2425770835732375, speed: 0.8})
        //             // map.once('zoomend', ()=>{
        //                 // animate();
        //                 // handlers.forEach(handler=>{
        //                 //     map[handler].enable();
        //                 // })
        //                 // markers[0].togglePopup();
        //             // })
        //         // })
        //     })
        //   })
        // }
      }
      animate()
      // console.log(features)
      // for (let i = 0; i < features.length; i++) {
      //   let k = i;
      //   setTimeout(() => {
      //     // console.log(i)
      //     if(features[i].properties.locationType !== "intermediate"){
      //       this.map.flyTo({
      //         center: features[i].geometry.coordinates,
      //         zoom: 10,
      //         pitch: 30,
      //         speed: 0.75
      //       })
      //       let detail = features[i].properties;
      //       // console.log(detail)
      //       this.props.displayInfo(detail);
      //     }else{
      //       this.map.flyTo({
      //         center: features[i].geometry.coordinates,
      //         zoom: 5,
      //         pitch: 30,
      //         speed: 0.5
      //       })
      //     }
      //     this.map.once('zoomend', ()=>{
      //       if(features[i].properties.locationType === 'beekeeper'){
      //         // console.log('CAR 15');
      //         this.map.setLayoutProperty('point', 'visibility', 'visible')
      //         this.map.setLayoutProperty('point', 'icon-image', 'truck')
      //         // console.log(this.map.getLayoutProperty('point', 'visibility'))
      //       }
      //       if(features[i].properties.locationType === 'processing'){
      //         // console.log('CAR 15');
      //         // this.map.setLayoutProperty('point', 'visibility', 'visible')
      //         this.map.setLayoutProperty('point', 'icon-image', 'truck')
      //         // console.log(this.map.getLayoutProperty('point', 'visibility'))
      //       }
      //       if(features[i].properties.locationType === 'export'){
      //         // console.log('truck');
      //         this.map.setLayoutProperty('point', 'icon-image', 'ferry-15')
      //         // console.log(this.map.getLayoutProperty('point', 'visibility'))
      //       }
      //       if(features[i].properties.locationType === 'import'){
      //         // console.log('CAR 15');
      //         this.map.setLayoutProperty('point', 'icon-image', 'truck')
      //         // console.log(this.map.getLayoutProperty('point', 'visibility'))
      //       }
      //       if(features[i].properties.locationType === 'distribution'){
      //         // console.log('CAR 15');
      //         this.map.setLayoutProperty('point', 'icon-image', 'truck')
      //         // console.log(this.map.getLayoutProperty('point', 'visibility'))
      //       }
      //       if(features[i].properties.locationType === 'distribution2'){
      //         // this.map.setLayoutProperty('point', 'icon-image', 'truck')
      //         this.map.setLayoutProperty('point', 'visibility', 'none')
      //         // console.log(this.map.getLayoutProperty('point', 'visibility'))
      //       }
      //     })
      //     // console.log(this.map.getLayer("point"))
      //     // console.log(features[i].properties.locationType)
      //     if(i===features.length-1){
      //       setTimeout(() => {
      //         this.map.once("zoomend", ()=>{
      //           this.map.setLayoutProperty('point', 'visibility', 'none')
      //           this.map.setPitch(0)
      //           this.map.fitBounds(bounds)
      //           this.map.once('zoomend', ()=>{
      //               this.map.flyTo({
      //                   zoom: this.map.getZoom()-0.2
      //                 })
      //               handlers.forEach(handler=>{
      //                   this.map[handler].enable();
      //               })
      //               // this.modalInstance.open();
      //               this.animating = false
      //               // map.setZoom(map.getZoom()-1)
      //               // map.flyTo({center: geojson.features[0].geometry.coordinates, speed: 0.5})
      //               // map.once('zoomend', ()=>{
      //               //     map.flyTo({zoom: 1.2425770835732375, speed: 0.8})
      //                   // map.once('zoomend', ()=>{
      //                       // animate();
      //                       // handlers.forEach(handler=>{
      //                       //     map[handler].enable();
      //                       // })
      //                       // markers[0].togglePopup();
      //                   // })
      //               // })
      //           })
      //         })
      //       }, 3000);
      //     }
      //   }, 6000 * (k+1));
        
      // }
    }

    // Route Geojson
    let linegeojson = {
      "type": "FeatureCollection",
      "features": [{
          "type": "Feature",
          "geometry": {
              "type": "LineString",
              "coordinates": []
          }
      }]
    };

    // Route Layer
    let linelayer = {
        'id': 'line-animation',
        'type': 'line',
        'source': {
            'type': 'geojson',
            'data': linegeojson
        },
        'layout': {
            'line-cap': 'round',
            'line-join': 'round'
        },
        'paint': {
            'line-color': '#fcd015',
            'line-width': 5,
            'line-opacity': 0.8
        }
    }

    // Condition for checking the type of data to be displayed -1= Routes else=Beekeepers
    if(this.props.id !== -1){
      // console.log(this.props)
      // Identifies which row to be displayed based upon beecode
      const data = this.props.honeys.find(el=>{
        return el.Bee_Code === this.props.id;
      })
      this.data = data;
      if(data){
        // console.log(data)
        // console.log(data.dist2Loc.match(/^[\[]*[0-9]*,*[0-9]*[\]]*$/))
        // if(data.bkLoc.match(/^[\[]*[0-9]*,*[0-9]*[\]]*$/) && data.expLoc.match(/^[\[]*[0-9]*,*[0-9]*[\]]*$/) && data.procLoc.match(/^[\[]*[0-9]*,*[0-9]*[\]]*$/) && data.distLoc.match(/^[\[]*[0-9]*,*[0-9]*[\]]*$/) && data.dist2Loc.match(/^[\[]*[0-9]*,*[0-9]*[\]]*$/)){

          let bkToProcGeo = data.bkToProcGeo
          let procToExpGeo = data.procToExpGeo
          let distToDist2Geo = data.distToDist2Geo
          let expToImpGeo = data.expToImpGeo
          let impToDistGeo = data.impToDistGeo
    
          
          const createarr = (coord)=>{
            let coordarr;
            // console.log(coord)
            // console.log(coord.indexOf('['))
            let arr = [];
            if(coord !== ''){
              if(coord.indexOf('[') === -1){
                // console.log(coord.indexOf('['))
                coordarr = coord.split(',')
                // console.log(coordarr)
                coordarr = coordarr.map((el)=>{
                  el = el.trim()
                  return parseFloat(el)
                })
                // console.log(coordarr)
                let feature = {
                  type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: coordarr
                    },
                    properties: {
                        locationType: 'intermediate'
                      }
                }
                arr.push(feature)
                // console.log(arr)
              }else{
                if(coord.indexOf('[') === coord.lastIndexOf('[')){
                  coordarr = JSON.parse(coord)
                  let feature = {
                    type: 'Feature',
                      geometry: {
                        type: 'Point',
                          coordinates: coordarr
                        },
                        properties: {
                          locationType: 'intermediate'
                        }
                      }
                      arr.push(feature)
                  // console.log(arr)
                }else{
                  coordarr = JSON.parse(coord)
                  // console.log(coordarr)
                  coordarr.forEach((el)=>{
                    let feature = {
                      type: 'Feature',
                      geometry: {
                            type: 'Point',
                            coordinates: el
                        },
                        properties: {
                            locationType: 'intermediate'
                        }
                      }
                    arr.push(feature)
                  })
                  // console.log(arr)
                }
              }
            }else{
              // console.log('empty ', arr)
            }
            return arr;
          }
          // console.log('bkToProcGeo')
          const arrbkToProcGeo = createarr(bkToProcGeo) 
          // console.log('procToExpGeo')
          const arrprocToExpGeo = createarr(procToExpGeo)
          // console.log('distToDist2Geo')
          const arrdistToDist2Geo = createarr(distToDist2Geo)
          // console.log('expToImpGeo')
          const arrexpToImpGeo = createarr(expToImpGeo)
          // console.log('impToDistGeo')
          const arrimpToDistGeo = createarr(impToDistGeo)
    
          // Arc for routes
          const arc = []
          // const steps = 8000;
          
          // On Map Load
          this.map.on('load', ()=>{
            // Resize the map
            this.map.resize();
            this.map.loadImage(truck, (err, image)=>{
              // this.map.loadImage("https://firebasestorage.googleapis.com/v0/b/purejoy-honey.appspot.com/o/truck.jpeg?alt=media&token=a3c68619-ef65-4e5a-91f4-050631b79f2c", (image, err)=>{
                // console.log(image)
              if(!err){
                this.map.addImage('truck', image)
              }
              pointLayer = {
                "id": "point",
                "source": "point",
                "type": "symbol",
                "layout": {
                    "icon-image": "truck",
                    "icon-size": 1.5, 
                    "icon-rotate": ["get", "bearing"],
                    "icon-rotation-alignment": "map",
                    "icon-allow-overlap": true,
                    "symbol-z-order": "source",
                    "icon-ignore-placement": true,
                    "visibility": 'none'
                }
              }
              // GeoJson for Locations
              let geojson = {
                type: 'FeatureCollection',
                features: [{
                  type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: this.convertCoord(data.bkLoc)
                    },
                    properties: {
                      title: 'Meet your beekeeper!',
                        locationType: 'beekeeper',
                        bee_code: data.Bee_Code,
                        beekeeper_photo: data.bkPhoto,
                        beekeeper_text_name: data.bkName,
                        beekeeper_text_location: data.bkForest,
                        beekeeper_text_location_body: data.bkForestDesc,
                        beekeeper_text_season: data.bkSeason,
                        beekeeper_text_about: data.bkBio,
                        beekeeper_text_apiary: data.bkApairy
                    }
                },
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: this.convertCoord(data.procLoc)
                    },
                    properties: {
                      title: 'PROCESSING FACILITY',
                        locationType: 'processing',
                        bee_code: data.Bee_Code,
                        processing_photo: data.procPhoto,
                        processing_text_heading: data.procName,
                        processing_text_body: data.procText
                    }
                },
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: this.convertCoord(data.expLoc)
                    },
                    properties: {
                        title: 'EXPORT LOCATION',
                        locationType: 'export',
                        bee_code: data.Bee_Code,
                        export_photo: data.expPhoto,
                        export_text_heading: data.expName,
                        export_text_body: data.expDesc
                      }
                },
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: this.convertCoord(data.impLoc)
                    },
                    properties: {
                        title: 'IMPORT LOCATION',
                        locationType: 'import',
                        bee_code: data.Bee_Code,
                        import_photo: data.impPhoto,
                        import_text_heading: data.impName,
                        import_text_body: data.impDesc
                      }
                },
                {
                  type: 'Feature',
                    geometry: {
                      type: 'Point',
                        coordinates: this.convertCoord(data.distLoc)
                      },
                      properties: {
                        title: 'DISTRIBUTION LOCATION',
                        locationType: 'distribution',
                        bee_code: data.Bee_Code,
                        distribution_photo: data.distPhoto,
                        distribution_text_heading: data.distName,
                        distribution_text_body: data.distDesc
                    }
                  }
                  ,{
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates:this.convertCoord(data.dist2Loc)
                    },
                    properties: {
                      title: 'DELIVERY LOCATION',
                      locationType: 'distribution2',
                      bee_code: data.Bee_Code,
                      distribution_photo: data.dist2Photo,
                     distribution_text_heading: data.dist2Name,
                      distribution_text_body: data.dist2Desc
                    }
                  }
                ]
              };
              // console.log('GEOJSON',geojson)
    
              // Point moving along the route  
              let point = {
                  "type": "FeatureCollection",
                  "features": [{
                      "type": "Feature",
                      "properties": {},
                      "geometry": {
                          "type": "Point",
                          "coordinates": geojson.features[0].geometry.coordinates
                      }
                  }]
              };
              this.map.addSource('point', {
                  "type": "geojson",
                  "data": point
              });
              this.map.addLayer(pointLayer);
    
              // let counter = 0;
              const animate = ()=>{
                  // console.log(this.map.getCenter())
                  point.features[0].geometry.coordinates = [this.map.getCenter().lng, this.map.getCenter().lat]
                  // console.log(counter, linegeojson.features[0].geometry.coordinates[counter])
                  // point.features[0].geometry.coordinates = linegeojson.features[0].geometry.coordinates[counter];
                
                  // point.features[0].properties.bearing = turf.bearing(
                  //     turf.point(linegeojson.features[0].geometry.coordinates[counter >= Math.round(lineDistance)-1 ? counter - 1 : counter]),
                  //     turf.point(linegeojson.features[0].geometry.coordinates[counter >= Math.round(lineDistance)-1 ? counter : counter + 1])
                  // );
                  // // console.log(linegeojson.features[0].geometry.coordinates[counter >= Math.round(lineDistance)-1 ? counter - 1 : counter],
                  // // linegeojson.features[0].geometry.coordinates[counter >= Math.round(lineDistance)-1 ? counter : counter + 1])
    
                  // // console.log(point.features[0].geometry.coordinates)
                  // // console.log(counter)
                  this.map.getSource('point').setData(point);
                  // // console.log(this.map.getSource('point'))
                  // counter = counter + 50;
    
                  // if (counter < Math.round(lineDistance)-1) {
                  if(this.animating){
                    requestAnimationFrame(animate);
                  }
                  // }
                  //  else{
                  //     counter = 0
                  //     requestAnimationFrame(animate);
                  // }
              }
              // console.log(arrbkToProcGeo)
              // console.log(arrprocToExpGeo)
              // console.log(arrdistToDist2Geo)
              // console.log(arrexpToImpGeo)
              // console.log(arrimpToDistGeo)
              // console.log(geojson)
              // console.log(geojson.features.slice(0,1));
    
              // Creating Route
              let featuresarray = geojson.features.slice(0,1);
              if(arrbkToProcGeo.length>0){
                featuresarray.push(...arrbkToProcGeo)
              }
              // console.log(geojson.features.slice(1,2));
              featuresarray.push(...geojson.features.slice(1,2))
              if(arrprocToExpGeo.length>0){
                featuresarray.push(...arrprocToExpGeo)
              }
              // console.log(geojson.features.slice(2,3));
              featuresarray.push(...geojson.features.slice(2,3))
              if(arrexpToImpGeo.length>0){
                featuresarray.push(...arrexpToImpGeo)
              }
              // console.log(geojson.features.slice(3,4));
              featuresarray.push(...geojson.features.slice(3,4))
              if(arrimpToDistGeo.length>0){
                featuresarray.push(...arrimpToDistGeo)
              }
              // console.log(geojson.features.slice(4,5));
              featuresarray.push(...geojson.features.slice(4,5))
              if(arrdistToDist2Geo.length>0){
                featuresarray.push(...arrdistToDist2Geo)
              }
              // console.log(geojson.features.slice(5,6));
              featuresarray.push(...geojson.features.slice(5,6))
              
              // console.log(featuresarray)
              geojson.features = featuresarray;
              // Creates marker from above GEOJSON
              geojson.features.forEach(feature=>{
                linegeojson.features[0].geometry.coordinates.push(feature.geometry.coordinates)
                if(feature.properties.locationType !== 'intermediate'){
                  let el = document.createElement('div');
                  el.classList.add('marker');
                  let marker = new mapboxgl.Marker(el);
                  marker.setLngLat(feature.geometry.coordinates)
                  marker.addTo(this.map)
                  
                  // Adds the location to route GeoJson 
                  
                  // Extends the location 
                  bounds.extend(feature.geometry.coordinates);
                  // el.addEventListener('click', ()=>{
                  //     let detail = feature.properties;
                  //     // console.log(detail)
                  //     this.props.displayInfo(detail);
                  //   })
                }
                })
      
              // Calculate the Line Distance
              let lineDistance = turf.length(linegeojson.features[0], {units: 'kilometers'});
              for (let i = 0; i < lineDistance; i += 1) {
                  let segment = turf.along(linegeojson.features[0], i, {units: 'kilometers'});
                  // Adds the segment into the Arc
                  arc.push(segment.geometry.coordinates);  
              }
      
              // Creates the desired routs
              linegeojson.features[0].geometry.coordinates = arc;
              // console.log('LineGeoJSON', linegeojson)
              // console.log('Arc',arc)
              // console.log('lineDistance',lineDistance)
              this.map.addLayer(linelayer)
              this.map.fitBounds(bounds)
              this.map.once('zoomend', ()=>{
                this.map.flyTo({
                    zoom: this.map.getZoom()-0.2
                })
                // map.setZoom(map.getZoom()-1)
                // map.flyTo({center: geojson.features[0].geometry.coordinates, speed: 0.5})
                // map.once('zoomend', ()=>{
                //     map.flyTo({zoom: 1.2425770835732375, speed: 0.8})
                    // map.once('zoomend', ()=>{
                        // animate();
                        // handlers.forEach(handler=>{
                        //     map[handler].enable();
                        // })
                        // markers[0].togglePopup();
                    // })
                // })
            })
  
              // Calls animation
              animateMap(geojson);
              animate();
            })
            })
        // }
        // else{
        //   this.notif = <div style={{position: "absolute", top:"100px", right: '30vw', zIndex: '100'}}>
        //                   <h1>OOPS!</h1>
        //                   <h4>Data Not Available</h4>
        //                 </div>
        // }

      }
      // else{
      //   this.notif = <div style={{position: "absolute", top:"100px", right: '30vw', zIndex: '100'}}>
      //                   <h1>OOPS!</h1>
      //                   <h4>Data Not Available</h4>
      //                 </div>

      // }

    // Displays all the BeeKeepers
    }else{
      // On Map Lods
      this.map.on('load', ()=>{

        // Resize
        this.map.resize();
        if(this.props.honeys.length>0){
          
          // Stores all the beekeepers in Array
          const data = this.props.honeys.map(el=>{
            return {
              Bee_Code: el.Bee_Code,
              bkLoc: el.bkLoc,
              bkPhoto: el.bkPhoto,
              bkName: el.bkName,
              bkForest: el.bkForest,
              bkForestDesc: el.bkForestDesc,
              bkSeason: el.bkSeason,
              bkBio: el.bkBio,
              bkApairy: el.bkApairy
            }
          })
          // console.log(data)
  
          // Geojson for beekeepers
          let geojson = {
            type: 'FeatureCollection',
            features: []
          }
  
          // Creates features for every beekeeper and adds to Beekeeper Geojson
          data.forEach(el=>{
            let feature = {
              type: 'Feature',
              geometry: {
                  type: 'Point',
                  coordinates: this.convertCoord(el.bkLoc)
                },
                properties: {
                  title: 'Meet your beekeeper!',
                  locationType: 'beekeeper',
                  beekeeper_photo: el.bkPhoto,
                  bee_code: el.Bee_Code,
                  beekeeper_text_name: el.bkName,
                  beekeeper_text_location: el.bkForest,
                  beekeeper_text_location_body: el.bkForestDesc,
                  beekeeper_text_season: el.bkSeason,
                  beekeeper_text_about: el.bkBio,
                  beekeeper_text_apiary: el.bkApiary
                }
            }
            geojson.features.push(feature);
          })
          this.props.displayInfo(geojson.features[0].properties);
          
          // Creates markers from Beekeeper GeoJson
          geojson.features.forEach(feature=>{
            let el = document.createElement('div');
            el.classList.add('marker');
            let marker = new mapboxgl.Marker(el);
            marker.setLngLat(feature.geometry.coordinates)
            marker.addTo(this.map)
  
            // Eventlistener to Display data
            el.addEventListener('click', ()=>{
              let detail = feature.properties;
              // console.log(detail)
              this.props.displayInfo(detail);
            })
          })
        }
      })
    }
  }
  // After Component is rendered
  componentDidMount(){
    // console.log(this.props)
    this.createMap();
    // this.modalInstance = M.Modal.init(this.modal,{opacity: 0});
    // console.log(this.modalInstance)
    // this.modalInstance.open()
  }
  componentDidUpdate(){
    // console.log('update')
    this.map.remove();
    this.createMap();
    // if(!this.data){
    //   this.notif = <div style={{position: "absolute", top:"100px", right: '30vw', zIndex: '100'}}>
    //             <h1>OOPS!</h1>
    //             <h4>Data Not Available</h4>
    //           </div>
    // }

  }

  //Just before Component Unmounts
  componentWillUnmount(){
    this.map.remove();
  }


  render() {

    
    return (
      <div className="map card-panel">
        {this.notif}
        <div style={this.style} ref={el=>this.mapContainer = el}></div>
        {/* <div ref={el=>{this.modal = el}} className="modal bottom-sheet">
          <div className="modal-content"> */}
            {/* <h4 className="center-align">Share your honey's journey!</h4> */}
            {/* <div className="center-align Demo__container">
              <div className="Demo__some-network">
                <FacebookShareButton
                  url={`https://trace.purejoyhoney.com/map/${this.props.id}`}
                  quote="Check this out! I've just traced my jar of Pure Joy Honey right back to the beekeeper in the forests of Tanzania"
                  className="Demo__some-network__share-button"
                >
                <FacebookIcon
                size={32}
                round
                />
                </FacebookShareButton>
              </div>
              <div className="Demo__some-network">
                <TwitterShareButton
                  url={`https://trace.purejoyhoney.com/map/${this.props.id}`}
                  title="Check this out! I've just traced my jar of Pure Joy Honey right back to the beekeeper in the forests of Tanzania"
                  className="Demo__some-network__share-button"
                >
                <TwitterIcon
                  size={32}
                  round />
                </TwitterShareButton>
              </div>
              <div className="Demo__some-network">
              <PinterestShareButton
                url={`https://trace.purejoyhoney.com/map/${this.props.id}`}
                media={media}
                windowWidth={1000}
                windowHeight={730}
                description="Check this out! I've just traced my jar of Pure Joy Honey right back to the beekeeper in the forests of Tanzania"
                className="Demo__some-network__share-button">
                <PinterestIcon size={32} round />
              </PinterestShareButton>
              </div>
            </div> */}
            {/* <h4 style={{marginTop: "10px"}}className="center-align">Follow Us</h4>
            <div className="center-align">
              <a style={{margin: "5px"}} href="https://www.instagram.com/purejoyhoney/"><img width="26" src={instagram} alt="Purejoy Honey Instagram"/></a>
              <a style={{margin: "5px"}} href="https://web.facebook.com/purejoyhoney"><img width="26" src={fb} alt="Purejoy Honey Facebook"/></a>
              <a style={{margin: "5px"}} href="https://twitter.com/purejoyhoney"><img width="26" src={twt} alt="Purejoy Honey Twitter"/></a>
            </div> */}
            {/* <h4 style={{marginTop: "10px"}}className="center-align">Or click around the map to explore more...</h4> */}
          {/* </div> */}
          {/* <div className="modal-footer" style={{textAlign: "center", background: "transparent"}}>
            <button className="modal-close waves-effect waves-light blue-grey lighten-5 btn">Explore</button>
          </div> */}
        {/* </div> */}
      </div>
    )
  }
}

const mapStatetoProps = (state)=>{
  // console.log('MAp State to Props', state)
  return {
    honeys: state.firestore.ordered.honeys?state.firestore.ordered.honeys: state.honeys.honeys
  }
}

const mapDispatchtoProps = (dispatch)=>{
  return{
    displayInfo: (detail)=> dispatch(displayInfo(detail))
  }
}

export default compose(
  connect(mapStatetoProps, mapDispatchtoProps),
  firestoreConnect([{collection: 'honeys'}])
)(Map)

// export default connect(mapStatetoProps,mapDispatchtoProps)(Map)
