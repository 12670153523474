import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'
  
  
  // Initialize Firebase
  var config = {
    apiKey: "AIzaSyAgnTtPJdaXaJdcqQ40sBUopnsRVL2Bplo",
    authDomain: "purejoy-honey.firebaseapp.com",
    databaseURL: "https://purejoy-honey.firebaseio.com",
    projectId: "purejoy-honey",
    storageBucket: "purejoy-honey.appspot.com",
    messagingSenderId: "818630328125"
  };
  firebase.initializeApp(config);

  firebase.firestore()

  export default firebase;