import React, { Component } from 'react'
import MapSidebar from './MapSidebar';
import Map from './Map';
import '../MapContainer.css';
import media from "../media.jpg"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon
} from 'react-share';


export class mapContainer extends Component {
  componentWillMount(){
    // console.log(this.props)
    if(this.props.match.params.id){
      this.id = this.props.match.params.id.toUpperCase();
    }else{
      this.id = -1
    }
  }

  state = {
    iconText: 'open_in_new',
    size: 'mobileSidebar'
  }

  resize(){
    let iconText = this.state.iconText === 'close'?'open_in_new':'close'
    let size = this.state.size==='expanded'?'mobileSidebar': 'expanded'
    this.setState((state)=>{
      return {
        ...state,
        iconText,
        size
      }
    })
    console.log('State', this.state)
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col l4 xl3 hide-on-med-and-down">
            <MapSidebar id={this.id}/>
          </div> */}
          <div className="col s12 m12 l12 xl12">
            <Map id={this.id}/>
          </div>
        </div>
        <div className={this.state.size}>
          <button onClick={this.resize.bind(this)} style={{position: 'absolute', top:0, right:0}} className="btn-floating red"><i className="material-icons">{this.state.iconText}</i></button>
          <MapSidebar id={this.id}/>
        </div>
        <div className="card-panel">
          {/* <div className="modal-content"> */}
            <h4 className="center-align">Share your honey's journey!</h4>
            <div className="center-align Demo__container">
              <div className="Demo__some-network">
                <FacebookShareButton
                  url={`https://trace.purejoyhoney.com/map/${this.id}`}
                  quote="Check this out! I've just traced my jar of Pure Joy Honey right back to the beekeeper in the forests of Tanzania"
                  className="Demo__some-network__share-button"
                >
                <FacebookIcon
                size={32}
                round
                />
                </FacebookShareButton>
              </div>
              <div className="Demo__some-network">
                <TwitterShareButton
                  url={`https://trace.purejoyhoney.com/map/${this.id}`}
                  title="Check this out! I've just traced my jar of Pure Joy Honey right back to the beekeeper in the forests of Tanzania"
                  className="Demo__some-network__share-button"
                >
                <TwitterIcon
                  size={32}
                  round />
                </TwitterShareButton>
              </div>
              <div className="Demo__some-network">
              <PinterestShareButton
                url={`https://trace.purejoyhoney.com/map/${this.id}`}
                media={media}
                windowWidth={1000}
                windowHeight={730}
                description="Check this out! I've just traced my jar of Pure Joy Honey right back to the beekeeper in the forests of Tanzania"
                className="Demo__some-network__share-button">
                <PinterestIcon size={32} round />
              </PinterestShareButton>
              </div>
            {/* </div> */}
            {/* <h4 style={{marginTop: "10px"}}className="center-align">Follow Us</h4>
            <div className="center-align">
              <a style={{margin: "5px"}} href="https://www.instagram.com/purejoyhoney/"><img width="26" src={instagram} alt="Purejoy Honey Instagram"/></a>
              <a style={{margin: "5px"}} href="https://web.facebook.com/purejoyhoney"><img width="26" src={fb} alt="Purejoy Honey Facebook"/></a>
              <a style={{margin: "5px"}} href="https://twitter.com/purejoyhoney"><img width="26" src={twt} alt="Purejoy Honey Twitter"/></a>
            </div> */}
            {/* <h4 style={{marginTop: "10px"}}className="center-align">Or click around the map to explore more...</h4> */}
          </div>
        </div>
      </div>
    )
  }
}

export default mapContainer
