import React, {Component} from 'react';
import {connect} from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import M from 'materialize-css';


export class MapSidebar extends Component{
  componentDidUpdate(){
    if(this.props.id !== -1){
      let options = {
        indicators: true,
        fullWidth: true
      }
      this.carouselInstance = M.Carousel.init(this.carousel,options)
      // setTimeout(() => {
      // this.carouselInstance.set(5) 
      this.carouselInstance.prev()
      setTimeout(() => {
        setTimeout(() => {
          console.log(1)
          this.carouselInstance.prev()
          setTimeout(() => {
            console.log(2)
            this.carouselInstance.prev()
            setTimeout(() => {
              console.log(3)
              this.carouselInstance.prev()
              setTimeout(() => {
                console.log(4)
                this.carouselInstance.prev()
                setTimeout(() => {
                  console.log(5)
                  this.carouselInstance.prev()
                }, 9000);
              }, 8000);
            }, 20000);        
          }, 8000);
        }, 8000);
      }, 6000);
        // let i = 0
        // setInterval(() => {
        //   i++
        //   if(i%3===0){
        //     setTimeout(() => {
        //       this.carouselInstance.prev()
        //     }, 3000);
        //   }else{
        //     this.carouselInstance.prev()
        //   }
        // }, 12000);
      // }, 0);
      console.log('Instance',this.carouselInstance)
    }
  }

  render(){
    const id = this.props.id
    if(id !== -1){
      const data = this.props.honeys.find(el=>{
        return el.Bee_Code === id;
      })
      console.log('data',data)
      if(data){
      this.carousels =   <div ref={el=>{this.carousel = el}} className="carousel">
                          <div className="carousel-item">
                           <div className="card">
                             <div className="card-image">
                               <img src={data.bkPhoto} alt=""/>
                               <h6 className="card-title">Meet your beekeeper!</h6>
                             </div>
                             <div className="card-content">
                               <h6 className="card-title">{data.bkName}</h6>
                               {/* <h6 className="card-title">Beekeeper Profile</h6> */}
                               <p>{data.bkBio}</p>
                               {/* <h6 className="card-title">Apiary</h6>
                               <p>{data.bkApiary}</p> */}
                               <h6 className="card-title">Forest</h6>
                               <p>{data.bkForest}</p>
                               <p>{data.bkForestDesc}</p>
                               <h6 className="card-title">Season</h6>
                               <p>{data.bkSeason}</p>
                             </div>
                           </div>
                          </div>
                          <div className="carousel-item">
                          <div className="card">
                            <div className="card-image">
                              <img src={data.procPhoto} alt=""/>
                              <h6 className="card-title">PROCESSING FACILITY</h6>
                            </div>
                            <div className="card-content">
                              <h6 className="card-title">{data.procName}</h6>
                              <p>{data.procText}</p>
                            </div>
                          </div>
                          </div>
                          <div className="carousel-item">
                            <div className="card">
                              <div className="card-image">
                                <img src={data.expPhoto} alt=""/>
                                <h6 className="card-title">EXPORT LOCATION</h6>
                              </div>
                              <div className="card-content">
                                <h6 className="card-title">{data.expName}</h6>
                                <p>{data.expDesc}</p>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="card">
                              <div className="card-image">
                                <img src={data.impPhoto} alt=""/>
                                <h6 className="card-title">IMPORT LOCATION</h6>
                              </div>
                              <div className="card-content">
                                <h6 className="card-title">{data.impName}</h6>
                                <p>{data.impDesc}</p>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="card">
                              <div className="card-image">
                                <img src={data.distPhoto} alt=""/>
                                <h6 className="card-title">DISTRIBUTION LOCATION</h6>
                              </div>
                              <div className="card-content">
                                <h6 className="card-title">{data.distName}</h6>
                                <p>{data.distDesc}</p>
                              </div>
                            </div>
                          </div>
                          <div className="carousel-item">
                            <div className="card">
                              <div className="card-image">
                                <img src={data.dist2Photo} alt=""/>
                                <h6 className="card-title">DELIVERY LOCATION</h6>
                              </div>
                              <div className="card-content">
                                <h6 className="card-title">{data.dist2Name}</h6>
                                <p>{data.dist2Desc}</p>
                              </div>
                            </div>
                          </div>
                        </div>

      }else{
        this.carousels = <div>
                          </div>
      }
      console.log(this.carousels)
      return(
          this.carousels
      )
      
    }else{
      return (        
      <div className="card-panel">
        {/* <h4>No Description</h4> */}
      </div>
      )
    }




    // // console.log(this.props)
    // if(this.props.detail){
    //   if(this.props.detail.locationType === 'beekeeper'){
    //     return(
    //       <div className="sidebar card">
    //         {/* <div className="card"> */}
    //           <div className="card-image">
    //             <img src={this.props.detail.beekeeper_photo} alt=""/>
    //             <h6 className="card-title">{this.props.detail.title}</h6>
    //           </div>
    //           <div className="card-content">
    //             <h6 className="card-title">{this.props.detail.beekeeper_text_name}</h6>
    //             {/* <h6 className="card-title">Beekeeper Profile</h6> */}
    //             <p>{this.props.detail.beekeeper_text_about}</p>
    //             {/* <h6 className="card-title">Apiary</h6>
    //             <p>{this.props.detail.beekeeper_text_apiary}</p> */}
    //             <h6 className="card-title">Forest</h6>
    //             <p>{this.props.detail.beekeeper_text_location}</p>
    //             <p>{this.props.detail.beekeeper_text_location_body}</p>
    //             <h6 className="card-title">Season</h6>
    //             <p>{this.props.detail.beekeeper_text_season}</p>
    //           </div>
    //         {/* </div> */}
    //      </div>
    //     )
    //   }
    //   if(this.props.detail.locationType === 'processing'){
    //     return(
    //       <div className="sidebar card">
    //         {/* <div className="card"> */}
    //           <div className="card-image">
    //             <img src={this.props.detail.processing_photo} alt=""/>
    //             {/* <h6 className="card-title">{this.props.detail.title}</h6> */}
    //           </div>
    //           <div className="card-content">
    //             <h6 className="card-title">{this.props.detail.processing_text_heading}</h6>
    //             <p>{this.props.detail.processing_text_body}</p>
    //           </div>
    //         {/* </div> */}
    //       </div>
    //     )
    //   }
    //   if(this.props.detail.locationType === 'export'){
    //     return(
    //       <div className="sidebar card">
    //         {/* <div className="card"> */}
    //           <div className="card-image">
    //             <img src={this.props.detail.export_photo} alt=""/>
    //             {/* <h6 className="card-title">{this.props.detail.title}</h6> */}
    //           </div>
    //           <div className="card-content">
    //             <h6 className="card-title">{this.props.detail.export_text_heading}</h6>
    //             <p>{this.props.detail.export_text_body}</p>
    //           </div>
    //         {/* </div> */}
    //       </div>
    //     )
    //   }
    //   if(this.props.detail.locationType === 'import'){
    //     return(
    //       <div className="sidebar card">
    //         {/* <div className="card"> */}
    //           <div className="card-image">
    //             <img src={this.props.detail.import_photo} alt=""/>
    //             {/* <h6 className="card-title">{this.props.detail.title}</h6> */}
    //           </div>
    //           <div className="card-content">
    //             <h6 className="card-title">{this.props.detail.import_text_heading}</h6>
    //             <p>{this.props.detail.import_text_body}</p>
    //           </div>
    //         {/* </div> */}
    //       </div>
    //     )
    //   }
    //   if(this.props.detail.locationType === 'distribution'){
    //     return(
    //       <div className="sidebar card">
    //         {/* <div className="card"> */}
    //           <div className="card-image">
    //             <img src={this.props.detail.distribution_photo} alt=""/>
    //             {/* <h6 className="card-title">{this.props.detail.title}</h6> */}
    //           </div>
    //           <div className="card-content">
    //             <h6 className="card-title">{this.props.detail.distribution_text_heading}</h6>
    //             <p>{this.props.detail.distribution_text_body}</p>
    //           </div>
    //         {/* </div> */}
    //       </div>
    //     )
    //   }
    //   if(this.props.detail.locationType === 'distribution2'){
    //     return(
    //       <div className="sidebar card">
    //         {/* <div className="card"> */}
    //           <div className="card-image">
    //             <img src={this.props.detail.distribution_photo} alt=""/>
    //             {/* <h6 className="card-title">{this.props.detail.title}</h6> */}
    //           </div>
    //           <div className="card-content">
    //             <h6 className="card-title">{this.props.detail.distribution_text_heading}</h6>
    //             <p>{this.props.detail.distribution_text_body}</p>
    //           </div>
    //         {/* </div> */}
    //       </div>
    //     )
    //   }
    // }else{
    //   return(
    //     <div className="sidebar card-panel">
    //       {/* <h4>No Description</h4> */}
    //     </div>
    //   )
    // }
    // if(this.props.deatil){
    //   return(
    //     <div>
    //     <div className="sidebar card-panel">
    //         <h4>{this.props.detail.title}</h4>
    //         <h6>Title</h6>
    //         <h6>Apiary</h6><span>Lorem, ipsum dolor.</span>
    //     </div>
    //   </div>
    //   )
    // }else{
    //   return (
    //     <div>
    //       <div className="sidebar card-panel">
    //           <h4>Description</h4>
    //           <h6>Title</h6>
    //           <h6>Apiary</h6><span>Lorem, ipsum dolor.</span>
    //       </div>
    //     </div>
    //   )
    // }
  }
}

const mapStatetoProps = (state)=>{
  // console.log('Root State Mapstatetoprops', state)
 return{
   honeys: state.firestore.ordered.honeys?state.firestore.ordered.honeys: state.honeys.honeys,
   detail: state.detail
 }
}

export default compose(
  connect(mapStatetoProps),
  firestoreConnect([{collection: 'honeys'}])
)(MapSidebar)

// export default connect(mapStatetoProps)(MapSidebar)


// style={{minHeight: '200px', maxHeight: '35vh', overflow: 'hidden'}} 
// style={{minHeight: '200px', maxHeight: '35vh', overflow: 'hidden'}} 
// style={{minHeight: '200px', maxHeight: '35vh', overflow: 'hidden'}} 
// style={{minHeight: '200px', maxHeight: '35vh', overflow: 'hidden'}} 
// style={{minHeight: '200px', maxHeight: '35vh', overflow: 'hidden'}} 
// style={{minHeight: '200px', maxHeight: '35vh', overflow: 'hidden'}} 
