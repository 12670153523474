
const detailReducer = (state = null, action)=>{
    if(action.type === 'display'){
        let detail = action.detail;
        // console.log(action)
        // console.log(detail)
        return {
            ...state,
            ...detail
        }
    }
    return state
}

export default detailReducer;