export const signin = (credentials)=>{
    return (dispatch, getState, {getFirebase})=>{
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then(()=>{
            dispatch ({type: 'LOGIN_SUCCESS'})
        }).catch((err)=>{
            dispatch({type: "LOGIN_ERROR", err})
        })
    }
}

export const signout = ()=>{
    return (dispatch, getState, {getFirebase})=>{
        const firebase = getFirebase();

        firebase.auth().signOut().then(()=>{
            dispatch ({type: 'LOGOT_SUCCESS'})
        })
    }
}