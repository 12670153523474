const initState = {
    honeys: [
        // {
        //     Bee_Code: "123AB",
        //     bkApiary: "Lugasa",
        //     bkBio: "John Magesa is from Lumbe in Tanzania and has 4 children. He's been beekeeping for 20 years. The money he makes for beekeeping is spent sending his children to school and investing in more beehives as well as his bean farm.",
        //     bkForest: "Moyowasi Forest",
        //     bkForestDesc: "The Moyowosi forest reserve is a fantastic location with thousands of hectares of untouched forests.",
        //     bkLoc: "31.0385519,-4.4597703",
        //     bkName: "John Magesa",
        //     bkPhoto: "https://materializecss.com/images/sample-1.jpg",
        //     bkSeason: "June/July",
        //     bkToProcGeo: "-4.422092,  30.323098",
        //     dist2Desc: "",
        //     dist2Loc: "-1.6980407,51.4756272",
        //     dist2Name: "North England",
        //     dist2Photo: "https://materializecss.com/images/sample-1.jpg",
        //     distDesc: "Here is where your honey was packed",
        //     distLoc: "-0.597513,52.872525",
        //     distName: "Derbyshire UK",
        //     distPhoto: "https://materializecss.com/images/sample-1.jpg",
        //     distToDist2Geo: "",
        //     expDesc: "Here your honey is loaded onto the ship",
        //     expLoc: "39.2800557,-6.8268736",
        //     expName: "Dar es Salaam Port, Tanzania",
        //     expPhoto: "https://materializecss.com/images/sample-1.jpg",
        //     expToImpGeo: "[[ 12.756775,  52.417627],[ 12.528181, 43.323733],[ 32.437170, 32.275071],[ 35.551635,-13.061276],[ 48.996906, -5.440826],[ 52.027841,  2.220637]]",
        //     impDesc: "Here is where your honey arrived..",
        //     impLoc: "-2.6980,51.4751",
        //     impName: "Bristol Port, UK",
        //     impPhoto: "https://materializecss.com/images/sample-1.jpg",
        //     impToDistGeo: "",
        //     procLoc: "29.6897146,-4.9018429",
        //     procName: "Kigoma, Tanzania",
        //     procPhoto: "https://materializecss.com/images/sample-1.jpg",
        //     procText: "Here is where we process your honey",
        //     procToExpGeo: ""

        // }
    ]
}

const honeyReducer = (state=initState, action)=>{
    // console.log('State honey reducer', state)
    if(action.type === 'UPDATE'){
        // console.log(action)
        state.honeys.push(action.honey);
        return state
    }
    if(action.type === 'UPDATE_ERR'){
        // console.log(action)
        return state
    }
    return state;
}

export default honeyReducer