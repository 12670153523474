import React, { Component } from 'react'
import { connect } from "react-redux";
import { signin } from '../../actions/authActions';
import { Redirect } from "react-router-dom";

export class Signin extends Component {
    state= {
        email: '',
        password: ''
    }

    handleSubmit = (e)=>{
        e.preventDefault()
        this.props.signin(this.state)
    }
    handleChange = (e)=>{
        this.setState({
            [e.target.id]: e.target.value
        }) 
    }

    render() {
        const {authError, auth } = this.props;
        // console.log(this.props)
        if(auth.uid) return <Redirect to="/" />
        return (
        <div className="container">
            <h1 style={{textAlign: 'center'}}>Sign In</h1>
            <div className="row">
                <form onSubmit = {this.handleSubmit} className="col offset-s3 s6">
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="email" type="email" onChange={this.handleChange} className="validate"/>
                            <label htmlFor="email">Email</label>
                            {/* <span className="helper-text" data-error="wrong" data-success="right">Email</span> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s12">
                            <input id="password" type="password" onChange={this.handleChange} className="validate"/>
                            <label htmlFor="password">Password</label>
                            {/* <span className="helper-text" data-error="wrong" data-success="right">Same</span> */}
                        </div>
                    </div>
                    <div className="center-align">
                        <button className="btn waves-effect waves-light">Sign In
                        <i className="material-icons right">send</i>
                        </button>
                        <div className="red-text center">
                            <p className="red-text center">{authError}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state)=>{
    return{
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        signin: (creds)=> dispatch(signin(creds))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Signin)
