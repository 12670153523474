import React, { Component } from 'react';
import {BrowserRouter, Route} from 'react-router-dom';

import Header from './layout/Header'
import MapContainer from './component/MapContainer';
import Dashboard from './component/Dashboard';
import './App.css';
import Signin from './component/auth/Signin';

class App extends Component {
	render() {
		// console.log(this.props)
		return (
			<BrowserRouter>
			<div className="App">
				<Header/>
				<Route exact path='/' component={Dashboard}/>
				<Route exact path='/map' component={MapContainer}/>
				<Route exact path='/map/:id' component={MapContainer}/>
				<Route exact path='/signin' component={Signin}/> 
			</div>
			</BrowserRouter>
		);
	}
}

export default App;
