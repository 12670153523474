import React, { Component } from 'react'
import FileReaderInput from 'react-file-reader-input';
import {connect} from 'react-redux';
import { firestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import csv from 'csvjson';

import '../Dashboard.css';
import updateInfo from '../actions/updateinfo';
import { compose } from 'redux';
import M from 'materialize-css';


export class dashboard extends Component {

  state = {
    honeys: []
  }
  tablehead
  tablebody
  reviewtable 
  modalInstance

  componentWillMount(){
    // console.log('Component will mount props', this.props)
    let honeys = this.props.honeys;
    console.log(honeys)
    if(honeys.length>0){
      let keys = Object.keys(honeys[0]).map(key=> {return key})
      this.tablehead = keys.map((th,i)=>
      <th key={i}>{th}</th>
      )
      this.tablebody = honeys.map((el,i)=>
      <tr key={i}>
        {Object.keys(el).map((key,d)=>
          <td key={d}>{el[key]}</td>  
          )}
      </tr>
      )
    }else{
      this.tablehead = <th>Database Empty</th>
    }

  }
  componentDidMount(){
    this.modalInstance = M.Modal.init(this.modal);
  }
  // componentWillUpdate(){
    // console.log('Component will update props', this.props)
  // }

  // componentDidUpdate(){
    // console.log('Component Did update props', this.props)
  // }
  // componentWillReceiveProps(){
    // console.log('componentwillreceive props')
  // }

  update = ()=>{
    // console.log('Upadating...')
    this.state.honeys.forEach(honey=>{
      this.props.update(honey)
    })
    // console.log('Props', this.props)
    this.setState((state)=>{
      return {...state}
    })
  }

  handleUpload = (e, results)=>{

    let datajson;
    let keys;
    
    let error = false;
    results.forEach(result => {
      const [pe] = result;
      console.log(pe.target.result)
      let data = pe.target.result;
      keys = data.split('\n');
      keys = keys[0];
      keys = keys.split(';')
      console.log(keys)
      let options = {
        delimiter: ';'
      }
      datajson = csv.toObject(data,options)
      console.log(datajson)
      datajson.forEach((el)=>{

      try {
        if(el['bkLoc'] !== "" && el['bkLoc'].includes('[') && el['bkLoc'].includes(']')){
          try {
            console.log(el['bkLoc'])
            let loc = JSON.parse(el['bkLoc'])
            if(loc.length>2){
              error = true
            }
          } catch (err) {
            console.log(err)
            error = true;
          }
        }        
        if(el['bkToProcGeo'] !== "" && el['bkToProcGeo'].includes('[') && el['bkToProcGeo'].includes(']')){
          try {
            console.log(el['bkToProcGeo'])
            let loc = JSON.parse(el['bkToProcGeo'])
            loc.forEach(el=>{
              if(el.length>2){
                error = true
              }
            })
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        if(el['dist2Loc'] !== "" && el['dist2Loc'].includes('[') && el['dist2Loc'].includes(']')){
          try {
            console.log(el['dist2Loc'])
            let loc = JSON.parse(el['dist2Loc'])
            if(loc.length>2){
              error = true
            }
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        if(el['distLoc'] !== "" && el['distLoc'].includes('[') && el['distLoc'].includes(']')){
          try {
            console.log(el['distLoc'])
            let loc = JSON.parse(el['distLoc'])
            if(loc.length>2){
              error = true
            }
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        if(el['distToDist2Geo'] !== "" && el['distToDist2Geo'].includes('[') && el['distToDist2Geo'].includes(']')){
          try {
            console.log(el['distToDist2Geo'])
            let loc = JSON.parse(el['distToDist2Geo'])
            loc.forEach(el=>{
              if(el.length>2){
                error = true
              }
            })
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        if(el['expLoc'] !== "" && el['expLoc'].includes('[') && el['expLoc'].includes(']')){
          try {
            console.log(el['expLoc'])
            let loc = JSON.parse(el['expLoc'])
            if(loc.length>2){
              error = true
            }
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        if(el['expToImpGeo'] !== "" && el['expToImpGeo'].includes('[') && el['expToImpGeo'].includes(']')){
          try {
            console.log(el['expToImpGeo'])
            let loc = JSON.parse(el['expToImpGeo'])
            loc.forEach(el=>{
              if(el.length>2){
                error = true
              }
            })
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        if(el['impLoc'] !== "" && el['impLoc'].includes('[') && el['impLoc'].includes(']')){
          try {
            console.log(el['impLoc'])
            let loc = JSON.parse(el['impLoc'])
            if(loc.length>2){
              error = true
            }
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        if(el['impToDistGeo'] !== "" && el['impToDistGeo'].includes('[') && el['impToDistGeo'].includes(']')){
          try {
            console.log(el['impToDistGeo'])
            let loc = JSON.parse(el['impToDistGeo'])
            loc.forEach(el=>{
              if(el.length>2){
                error = true
              }
            })
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        if(el['procLoc'] !== "" && el['procLoc'].includes('[') && el['procLoc'].includes(']')){
          try {
            console.log(el['procLoc'])
            let loc = JSON.parse(el['procLoc'])
            console.log(loc)
            if(loc.length>2){
              error = true
            }
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        if(el['procToExpGeo'] !== "" && el['procToExpGeo'].includes('[') && el['procToExpGeo'].includes(']')){
          try {
            console.log(el['procToExpGeo'])
            let loc = JSON.parse(el['procToExpGeo'])
            loc.forEach(el=>{
              if(el.length>2){
                error = true
              }
            })
          } catch (err) {
            console.log(err)
            error = true;
          }
        }
        
      } catch (err) {
        console.log(err)
        error=true
      }
      })
      console.log('converted data',datajson)
      // console.log(this.state)
      // console.log(this.props)
    });

    if(error){
      console.log('Error')
      this.modalInstance.open()
      return;
    }
    let honeys = datajson;

    let rtablehead = keys.map((th,i)=>
    <th key={i}>{th}</th>
    )

    let rtablebody = datajson.map((el,i)=>
    <tr key={i}>
      {Object.keys(el).map((key,d)=>
        <td key={d}>{el[key]}</td>  
        )}
    </tr>
    )
    this.reviewtable = 
      <div>
        <nav className="blue-grey lighten-5">
          <div className="nav-wrapper container">
            <span href="#" className="brand-logo black-text">Review Data</span>
            <ul className="right">
              <li>
                <button onClick={this.update} className="btn waves-effect waves-light">Update
                  <i style={{lineHeight: "inherit"}} className="material-icons right">cloud</i> 
                </button>
              </li>
            </ul>
          </div>
        </nav>
        <div className="datatable card-panel">
          <table>
            <thead>
              <tr>
                {rtablehead}
              </tr>
            </thead>
            <tbody>
              {rtablebody}
            </tbody>
          </table>
        </div>
      </div>
    this.setState((state)=>{
      return{
        ...state,
        honeys
      }
    })
  }

  render() {
    const{ auth } = this.props;
    if(!auth.uid) return <Redirect to = "/signin"/>
    // console.log('render...')
    // console.log('render props', this.props)
    // console.log('Length', this.props.honeys.length)
    let honeys = this.props.honeys;
    if(honeys.length>0){
      let keys = Object.keys(honeys[0]).map(key=> {return key})
      console.log(keys)
      this.tablehead = keys.map((th,i)=>
      <th key={i}>{th}</th>
      )
    }

    console.log(honeys)
    this.tablebody = honeys.map((el,i)=>
    <tr key={i}>
      {Object.keys(el).map((key,d)=>
        <td key={d}>{el[key]}</td>  
        )}
    </tr>
    )
    return (
      <div className="container-fluid">
        <h1 style={{textAlign: 'center'}}>Dashboard</h1>
        <div className="container">
          <nav className="blue-grey lighten-5">
            <div className="nav-wrapper">
              <span className="brand-logo center black-text">Honey Data</span>
            </div>
          </nav>
          <div className="card-panel">
            <FileReaderInput as="text" className="center-align" onChange={this.handleUpload}>
              <button className="btn">Choose File</button>
            </FileReaderInput>
          </div>
        </div>
        {this.reviewtable ? this.reviewtable : null}
        <nav className="blue-grey lighten-5">
          <div className="nav-wrapper container">
            <span className="brand-logo center black-text">Database</span>
          </div>
        </nav>
        <div className="datatable card-panel">
          <table>
            <thead>
              <tr>
                {this.tablehead? this.tablehead : null}
              </tr>
            </thead>
            <tbody>
              {this.tablebody? this.tablebody : null}
            </tbody>
          </table>
        </div>
        <div ref={el=>{this.modal = el}} className="modal">
          <div className="modal-content">
            Please Check your data. Either field values are inaccurate or some fields are missing.
          </div>
        </div>
      </div>
    )
  }
}

const mapStatetoProps = (state)=>{
  console.log('root state', state)
  return{
    honeys: state.firestore.ordered.honeys?state.firestore.ordered.honeys: state.honeys.honeys,
    detail: state.detail,
    auth: state.firebase.auth
  }
}

const mapDispatchtoProps = (dispatch)=>{
  return{
    update: (honey)=> dispatch(updateInfo(honey))
  }
}

export default compose(
  connect(mapStatetoProps, mapDispatchtoProps),
  firestoreConnect([
    {collection: 'honeys'}
  ])
)(dashboard)
