import React, { Component } from 'react'
import {NavLink} from 'react-router-dom';
import { connect } from "react-redux";
import M from 'materialize-css';
import '../Header.css'
import { signout } from '../actions/authActions';

export class Header extends Component {
  componentDidMount(){
    M.AutoInit()
    M.Sidenav.init(this.sidenav)
    if(this.props.auth.uid){
      M.Dropdown.init(this.dropdown)
    }
  }
  componentDidUpdate(){
    if(this.props.auth.uid){
      // console.log(this.dropdown)
      M.Dropdown.init(this.dropdown)
    }
  }
  render() {
    const {auth } = this.props;
    return (
      <div>
        <nav>
          <div className="nav-wrapper container">
            {/* <a href="https://www.purejoyhoney.com" className="brand-logo black-text">PUREJOY</a> */}
            <a href="/" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons black-text">menu</i></a>
            <ul id="nav-mobile" className="right hide-on-med-and-down">
              {auth.uid?
                <li><NavLink className="black-text" to="/">Dashboard</NavLink></li>:null
              }
                {/* <li><NavLink className="black-text" to="/map">Map</NavLink></li> */}
                {/* <li><button className="waves-effect waves-light lime darken-3 btn">Sign In</button></li> */}
                {auth.uid?
                <li><a href="#dropdown" data-target="accdropdown" ref={el=>{this.dropdown= el}}  className="btn-flat dropdown-trigger"><i className="large material-icons">account_circle</i></a>
                <ul id="accdropdown" className="dropdown-content">
                  <li><button onClick={this.props.signOut} className="btn-flat">Sign Out</button></li>
                </ul>
                </li>:null
              }
              {/* <li><NavLink className="black-text" to="/signin">Sign In</NavLink></li> */}
            </ul>
          </div>
        </nav>
        <ul ref={(el)=>{this.sidenav = el}} className="sidenav lime lighten-1" id="mobile-demo">
          {auth.uid?
          <li><NavLink className="waves-effect waves-light btn-flat" to="/">Dashboard</NavLink></li>:null
          }
          {/* <li><NavLink className="waves-effect waves-light btn-flat" to="/map">Map</NavLink></li> */}
          {auth.uid?
          <li><button onClick={this.props.signOut} style={{width: "auto", marginLeft: '47px'}} className="waves-effect waves-light btn">Sign Out</button></li>:null
          }
        {/* <li><NavLink to="/signin" className="waves-effect waves-light btn-flat">Sign In</NavLink></li> */}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state)=>{
  // console.log(state)
  return {
    auth: state.firebase.auth
  }
}

const mapDispathToProps = (dispatch)=>{
  return {
    signOut: ()=>dispatch(signout())
  }
}
export default connect(mapStateToProps,mapDispathToProps)(Header)
