const updateInfo = (honey)=>{
    // console.log(honey)
    return (dispatch, getState, {getFirebase, getFirestore})=>{
        const firestore = getFirestore();
        firestore.collection('honeys').doc(honey.Bee_Code).set({
            ...honey
        }).then(()=>{
            dispatch({type: 'UPDATE', honey})
        }).catch((err)=>{
            dispatch({type: 'UPDATE_ERR'})
        })
    }
}

export default updateInfo;